.list-padding {
    padding-left: 20px;
}

.left-border-divider {
    border-left: 1px solid #999;
    margin-left: -20px;
    padding-left: 60px;
}

.card {
    margin-bottom: 25px;
}

code {
    font-size: 11px;
}