.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

hr.component-divider {
    background: #222222;
    height: 2px;
}

.no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.white-body {
    background-color: #fff;
    padding: 20px;
}

.accordion-body {
    padding: 10px !important;
}

.accordion-header h4 {
    display: inline;
    cursor: pointer;
}

.demo-box {
    border: 1px solid #eee;
    padding: 10px;
}

.demo-box-options {
    padding: 10px;
    margin-bottom: 5px;
    background: repeating-linear-gradient(
            90deg,
            #eaeff2,
            #eaeff2 1px,
            #ffffff 1px,
            #ffffff 2px
    );
}

.package-box {
    padding: 10px;
    margin-bottom: 15px;
    background: white;
    border-bottom: 2px solid #41b5dc;
    border-radius: 4px;
}

.half-field {
    line-height: 25px;
}

.quick-links-container {
    line-height: 120%;
}

.flag-lang {
    font-size: 30px;
    padding-top: 2px;
    position: absolute;
}

.badge-new {
    background-color: #f00;
    color: #fff;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 2px;
}

.badge-new-box {
    padding: 3px 5px 3px 5px;
    float: left;
}

.badge-new-text {
    padding: 1px 5px 1px 5px;
}